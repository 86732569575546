import React, { Component } from "react";
import mixpanel from 'mixpanel-browser';
import MixpanelProvider from 'react-mixpanel';

import {Mixpanel} from './Mixpanel'

import {handleTransaction} from './components/handleTransaction.js'

import WelcomeBox from "./components/welcome/welcome-box";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import 'firebase/compat/database';
import logo from './assets/images/robin-logo.png';
import Profile from './components/profile/profile-v2'
import Loading from './assets/images/loading.gif'


import School from './components/school/school'
import FinGlossary from './components/course/fin-glossary'

import './assets/css/bootstrap-grid.css';
import Game from './components/game';
import QuestLevels from './components/quest/quest-levels';
import ManagerMini from './components/quest/quest';
import Manager from './components/quest/manager';
import OldQuest from './components/quest/old-quest';
import PricetagImg from './assets/images/pricetag.png'; 


import Street from './components/street/street';
import Subscription from './components/suscription/subscription'
import axios from 'axios';


import Levels from './components/games/levels'

import ExtraLevels from './components/games/extra-levels'




import './components/app.css'


import Store from './components/shop/store'
import WallStreet from "./components/wallstreet/wallstreet";
import Treehouse from "./components/treehouse/treehouse";
import BerryStore from './components/berry-store/berry-store'
import PricetagNew from "./components/pricetag/pricetag-new";
import Chat from "./components/chat/firestorechat";
import GroupChat from "./components/chat/group-chat";
import FriendList from "./components/chat/friend-list";

import BuyCourse from './components/suscription/buy-course'

import Gourmet from "./components/gourmet/gourmet";
import Modal from "./components/gourmet/modal";
import House from "./components/treehouse/house/house";
import CourseView from "./components/course/course-view";
import SessionView from "./components/course/session-view";
import NavBar from "./components/navigation/nav-bar";
import FinanceCourseView from "./components/course/fin-course";
import QuizIntro from "./components/course/quiz-intro";
import Quiz from "./components/course/quiz";
import Refer from "./components/shop/refer";
import ExtraActivity from "./components/course/extra-activity";
import ChoreView from "./components/chores/chore-view";
import VideoModal from "./components/layout/video-modal";
import CourseCheckout from "./components/charge/course-checkout";
import Stepper from "./components/stepper/stepper";
import AddressForm from "./components/shop/address-form";
import Avatar from "./components/avatar/avatar-edit";
import BerrySession from "./components/course/berry-session";
import Tutoring from "./components/school/tutoring";
import AuxRenderer from "./components/aux-renderer";
import Projects from "./components/school/projects";
import ProjectView from "./components/school/project-view";
import KhanActivites from "./components/school/khan-activities";
import KhanActivity from "./components/school/khan-activity";
import JoinWallstreet from "./components/wallstreet/join-wallstreet";
import LMS from "./components/LMS/lms";
import CurricularActivites from "./components/LMS/curricular-activities";
import CurricularActivities from "./components/LMS/curricular-activities";
import CurricularActivity from "./components/LMS/curricular-activity";
import Agenda from "./components/LMS/agenda-v2";
import LanguageAreas from './components/LMS/language-areas'
import LanguageActivities from "./components/LMS/language-activitities";
import RecurrentActivities from "./components/LMS/recurrent-activitities";
import SubjectSelector from './components/LMS/subject-selector'
import SchoolOptions from "./components/LMS/school-options";
import IFA from "./components/ifa-emails";
import ProjectsLibrary from "./components/LMS/projects-library";
import StudentUploads from "./components/LMS/student-uploads";
import CommentsViewer from './components/LMS/comments-viewer'

import AddActivityModal from "./components/LMS/add-activity-modal";
import NotionPage from "./components/LMS/notion-page-new";
import Subject from "./components/LMS/subject";
import Topic from "./components/LMS/topic";
import CityMap from "./components/profile/map-zoom-out";


class App extends Component {
  constructor() {
    super();

    const config = {
      // apiKey: "AIzaSyCO35coaVCe2soOvCzLQOlhXLLi1FhfSCs",
      // authDomain: "robin-learn.firebaseapp.com",
      // databaseURL: "https://robin-learn.firebaseio.com",
      // projectId: "robin-learn",
      // storageBucket: "robin-learn.appspot.com",
      // messagingSenderId: "389285299158"
      apiKey: "AIzaSyCO35coaVCe2soOvCzLQOlhXLLi1FhfSCs",
      authDomain: "robin-learn.firebaseapp.com",
      databaseURL: "https://robin-learn.firebaseio.com",
      projectId: "robin-learn",
      storageBucket: "robin-learn.appspot.com",
      messagingSenderId: "389285299158",
      appId: "1:389285299158:web:c0712df344e2dd75851a77"

      // apiKey: "AIzaSyAqJIDZ8w30Ls8jMrwj8z67L9yeABCukhY",
      // authDomain: "robin-play.firebaseapp.com",
      // databaseURL: "https://robin-play.firebaseio.com",
      // projectId: "robin-play",
      // storageBucket: "robin-play.appspot.com",
      // messagingSenderId: "529931431031",
      // appId: "1:529931431031:web:4ab4e4d9ad706cb3c6ea72"
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }



    this.state = {
      view: "welcome",
      welcomeView: "login",
      user: null,
      questLevel: "",
      game: "",
      school: undefined, 
      child: "",
      loading: false,
      session: "",
      notifications: '',
      notificationArray: [], 
      addActivity: '',
      activityID:"",
      subjectGrade: ""
    };

    this.handleChangeWelcomeView = this.handleChangeWelcomeView.bind(this);
    this.handleStartApp = this.handleStartApp.bind(this);
    this.setUser = this.setUser.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
    this.handleSessionView = this.handleSessionView.bind(this);

    this.renderQuest = this.renderQuest.bind(this)
    this.handlePurchase = this.handlePurchase.bind(this)
  }


  handleViewChange(view){
    window.scrollTo(0, 0);
    
    console.log(view)
    if(view != 'finanzas'){
      this.setState({
        view: view
      })
      console.log("Not fin")
    }

    else{
      alert("finanzas")
    }
    
    
  }

  handleSessionView(session, sessionIndex){
    
    const course = this.state.view
    this.setState({
      view:"session-view", 
      session: session, 
      sessionIndex: sessionIndex, 
      course: course
    })
  }

  handleCourseBuy = (course)=>{
    
    const view = this.state.view
    this.setState({
      view:"buy-course", 
      course: view
    })
  }

  handleFinishTutorial = () => {
    firebase.database().ref("users/"+this.state.user.uid+"/tutorial").set(true)
  }

  handleExtraView = (sessionIndex) => {

    firebase.database().ref("users/"+this.state.user.uid+"/extra").once("value", (snap) => {
      var played = snap.val()

      

        axios.get("https://workshop-payments.herokuapp.com/generate-timestamp").then((data)=>{
          var timestamp = data.data.timestamp

          timestamp = timestamp-72000000

          if(timestamp>=(played)){

            const course = this.state.view

            this.setState({
              view:"extra-view", 
              sessionIndex: sessionIndex, 
              course: course
            })

          }
          else{
            alert("Ya subiste una actividad o clase hoy, vuelve después de 24 horas para desbloquear la nueva actividad extra :)")
          }
        })
      })
  }

  handleModuleView = (game, title) =>{

    this.setState({
      view: game, 
      title: title, 
    })
  }

  toggleLoading = (shouldLoad) =>{
    this.setState({
      loading: shouldLoad
    })

  }

  selectFriendChat =(friendID)=>{
    this.setState({
      friendChat: friendID,
      view: "private-chat"
    })
  }

  handleCourseChange=(course)=>{
    this.setState({
      auxCourse: course, 
      view: "aux-renderer"
    })
  }

  handleViewChange(view, game){
    window.scrollTo(0, 0);

    if(view== "finanzas"){
      this.setState({
        course:"finanzas"
      })
    }
    // if(view == "finanzas") {
    //   window.open("https://robinacademy.com/finanzas.html", "_blank")
    // }
    // else{
      console.log(view,game)
      this.setState({
        view: view, 
        game: game
      })

      if(window.screen.width < 750 || window.screen.height < 600 ) {
        if(game=="quest"){
          this.setState({
            game: "quest-mini"
          })
        }
      }
    // }
    
  }

  

  

  setUser = (user) => {

    Mixpanel.identify(user.uid);
    mixpanel.people.set({
      "$uid": user.uid,    // only special properties need the $
      
      
      "$last_login": new Date(),  // properties can be dates...
    });
    Mixpanel.track('Successful Login');


    // alert("El acceso a la aplicación de Robin dejará de estar disponible el 1 de noviembre para quienes no hayan asistido a nuestros cursos. Para conservar tu cuenta mandanos un mensaje diciendonos el mes que asisitiste a nuestro bootcamp. En noviembre podrás inscribirte a nuestro curso online y acceder a más contenido en robinacademy.com :)")
    
    const that = this
    firebase.database().ref("users/"+user.uid).on("value", function(snap){
      const child = snap.val()
      
      that.setState({
        user: user,
        balance: child.balance,
        name: child.name,
        isTutor: false, 
        avatar: child.avatar, 
        subscription: child.subscription, 
        school: child.school, 
        child: child, 
        treehouse: child.treehouse, 
        tutorial: child.tutorial
        // course: child.course
      })

      mixpanel.people.set({
        "$group": child.vg1    // only special properties need the $
        
        
      });

      console.log(child.vg1)

      if(!child.treehouse){
        that.setState({
          treehouse: "https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/hq%2Ftree.png?alt=media&token=8f66f8bd-a9f5-49eb-85e6-911e3d62716a"
        })
      }

      // if(child.course != "finanzas"){
      //   alert("Robin dejó de ser una aplicación gratuita, si fuiste alumno de un curso de Robin mandanos un correo a hola@robinbank.co con el evento al que asististe. Si no puedes adquirir el curso aquí...")
      //   window.location.href="/finanzas.html"
      // }
      
      
      console.log(child)

      that.handleStartApp()


      firebase.database().ref("notifications/"+user.uid).on("value", snap=>{
        console.log(snap.val())
        var notifications = []
        if(snap.val()){
          notifications = snap.val()
        }
        var notificationArray =[]

        Object.keys(notifications).map(id=>{
          var notification = notifications[id]
          notification.options = {
            ok: ()=>{that.closeModal(id)}
          }

          notificationArray.push(notification)

          
        })

        notificationArray.reverse()
        that.setState({notificationArray})

        
      })

      // firebase.database().ref("notifications/"+user.uid+"/1").on("value", snap=>{
      //   console.log(snap.val())
      //   var notifications = snap.val()


      //   if(notification){
      //     notification.options = {
      //       ok: ()=>{that.closeModal("1")}
      //     }
      //     that.setState({
      //       notifications: notification
      //     })
      //   }
      //   else{
      //     that.setState({
      //       notifications: false
      //     })
      //   }
        
        
      // })

    })

    firebase.database().ref("q-progress/"+user.uid).on("value", function(snap){
      that.setState({
        questLevels: snap.val()
      })
    })
  }

  handleChangeWelcomeView(event) {
    this.setState({
      welcomeView: event.target.value
    });
  }

  renderQuest(questLevel){

    this.toggleLoading(true)

    const that = this

    firebase.database().ref("users/"+this.state.user.uid+"/"+this.state.game).once("value", (snap) => {
      var played = snap.val()

      if(this.state.game != "gourmet" && !played) {
        alert("Todavía no desbloqueas este juego. Debes terminar el nivel 5 del juego anterior :)")
        this.toggleLoading(false)
      }

      else{
        axios.get("https://workshop-payments.herokuapp.com/generate-timestamp").then((data)=>{
          var timestamp = data.data.timestamp

          timestamp = timestamp-72000000


          if(timestamp>(played)){


            if(questLevel>150){
              
              // firebase.database().ref("users/"+this.state.user.subscription)
              
              axios.post("https://workshop-payments.herokuapp.com/validate",
                {
                  subscription: this.state.subscription
                })
                .then((data)=>{
        
                  let status = data.data.subscription.status
                  if (status == "active" || status == "trialing"){
                    this.setState({
                      questLevel: questLevel+1, 
                      view: that.state.game
                    })
                  }
                  else{
                    alert("Ya jugaste todos los juegos gratuitos. Para seguir jugando y ganar Robins cada día suscríbete :)")
                    this.setState({
                      view: "subscription"
                    })
                  }
              })
              .catch((err)=>{
                alert("Ya jugaste todos los juegos gratuitos. Para seguir jugando y ganar Robins cada día suscríbete :)")
                  this.setState({
                    view: "subscription"
                  })
              })
            }
    
            else{
              //Send the selected quest level to quest component (plus one cause it's an array)
              this.setState({
                questLevel: questLevel+1, 
                view: this.state.game
              })
            }
            
          }
          else{
            alert("Ya completaste un reto del día de hoy. Vuelve mañana para realizar otro :)")
            this.toggleLoading(false)
          }

        })
      
      }
    

    })


    
    
  }

  joinWallstreetGame = gameID =>{
    this.setState({
      gameID, 
      view: "wallstreet"
    })
  }

  setAvatar = (url)=>{
    const uid = this.state.user.uid;

    firebase.database().ref("users/" + uid).update({avatar: url})

    this.setState({
      profile: url, 
      view: "app"
    })
  }

  handleProjectView=(project)=>{
    this.setState({
      project: project, 
      view:"project-view"
    })
  }

  handleActivityView=(activity)=>{
    this.setState({
      curricularActivity: activity, 
      view: "khan-activity"
    })
  }

  handleStartApp() {
    if (this.state.isTutor) {
      this.setState({
        view: "app-tutor"
      });
    } else {
      this.setState({
        view: "app"
      });
    }
  }

  handlePurchase(item){
    this.setState({
      view: "address-form",
      purchaseItem: item
    })
  }




  viewCurricularActivities=(topic, grade)=>{
    this.setState({
      view: "curricular-activities",
      LMSTopic: topic, 
      selectedGrade: grade
    })
  }

  viewCurricularActivity=(activityData, backView, id)=>{
    this.setState({
      view: "curricular-activity",
      LMSActivity: activityData, 
      backView: backView,
      activityID: id
    })
  }

  viewLanguageActivities=(area)=>{
    this.setState({
      view: "language-activities",
      languageArea: area
    })
  }

  viewLMS = (subject)=>{
    this.setState({
      view: "lms", 
      subject: subject
    })
  }

  //Replaces viewLMS in the new notion update
  viewSubject = (subject) =>{

    firebase.firestore().collection("curricular-progress").doc(this.state.user.uid).onSnapshot((snap)=>{
          
      let progress = snap.data()
      if(!progress){
        progress = {
          "Lengua Materna":"7",
          "Math": "7",
          "Science": "7"
        }
      }
      console.log(progress)
      this.setState({
        subjectGrade: progress,
        view: "subject",
        subject: subject
      })
    })

    // this.setState({
    //   view: "subject",
    //   subject: subject
    // })
  }

  //Replaces viewCurricularActivities in notion update
  viewTopic = (topic)=>{
    this.setState({
      view:"topic",
      LMSTopic:topic
    })
  }

  addTask = (act,dateFormat, timestamp) =>{
    console.log(act, dateFormat, timestamp)
    // const timestamp = +new Date()
    
    // var task = act
    var task = {}
    task.status="undone"
    task.timestamp=timestamp
    task.activity = act
    task.activity_key = act.id
    if(!task.activity_key){
      task.activity_key = act.activity_key
    }

    if(!act.properties){
      act.properties = {
        nombre: {
            title: [{
                plain_text: act.nombre
            }]
        },
        tiempo: {
            number:act.tiempo
        }
      }
    }
    // task.name=act.nombre
    task.name=act.properties.nombre.title[0].plain_text


    if(task.area==="recurrent-activities"){
      task.activity_key=task.activity_key+"_"+task.timestamp  
    }


    if(!task.timestamp){
      task.timestamp = +new Date()
    }
    
    console.log(dateFormat)
    //Read agenda for that day 
    firebase.firestore().collection("students/"+this.state.user.uid+"/agenda-v3")
    .doc(dateFormat)
    .get().then((snap)=>{
      console.log(snap.data())
     
      let agenda = snap.data()
      if (!agenda){
        agenda = {
          timestamp: timestamp,
          dayKey: dateFormat,
          activities: {}
        }
      }
      agenda['dayKey'] = dateFormat
      agenda["timestamp"]= timestamp
      console.log(task.activity_key)

      // Add task to activities obj
      if(!agenda["activities"]){
        agenda["activities"]={}
      }
      agenda["activities"][task.activity_key] = task
      

      if(!agenda.timestamp){
        agenda.timestamp = +new Date()
      }

      this.setState({
        addActivity: ''
      })


      console.log(agenda)
      //Write updated agenda for that day 
      firebase.firestore().collection("students/"+this.state.user.uid+"/agenda-v3")
      .doc(dateFormat)
      .set(agenda)
    })

    
  }


  addCustomTask = (act,dateFormat, timestamp) =>{

    // const timestamp = +new Date()
    console.log(act.tiempo)
    
    var task = act
    task.activity={
      properties: {
        tiempo:{
          number:{}
        }
      }
    }
    task.status="undone"
    task.timestamp=timestamp
    task.activity_key = "custom_"+timestamp
    task.activity.id = "custom_"+timestamp
    task.activity["properties"]["tiempo"]["number"] = act.tiempo
    task.link = act.acceso
    task.instrucciones = act.instrucciones


    if(task.area==="recurrent-activities"){
      task.activity_key=task.activity_key+"_"+task.timestamp  
    }


    
    console.log(dateFormat)
    //Read agenda for that day 
    firebase.firestore().collection("students/"+this.state.user.uid+"/agenda-v3")
    .doc(dateFormat)  
    .get().then((snap)=>{
      console.log(snap)
     
      var agenda = snap.data()
      if (!agenda){
        agenda = {
          timestamp: timestamp,
          dayKey: dateFormat,
          activities: {}
        }
      }
      agenda['dayKey'] = dateFormat
      agenda["timestamp"]= timestamp
      console.log(task)

      // Add task to activities obj

      agenda["activities"][task.activity_key] = task
      
    

      console.log(agenda)
      console.log(dateFormat)
      //Write updated agenda for that day 
      firebase.firestore().collection("students/"+this.state.user.uid+"/agenda-v3")
      .doc(dateFormat)
      .set(agenda)


      

    })

    
  }


// Deprecated below the this.addTask(act,key) code
  addActivity = (act, key)=>{
    
      this.setState({
        addActivity: act
      })
      // this.addTask(act,key)
     
      // const act = this.state.activities[activity]

      const timestamp = +new Date()

      

      // var task = {act}
      var task = {}
      task.status="undone"
      task.timestamp=timestamp
      // task.name=act.nombre
      task.name="act.nombre"



      const date = new Date()
      var day = date.getDate()
      var month = date.getMonth() + 1
      var year = date.getFullYear()


      
      var dateFormat = this.state.agendaDate

      if(!dateFormat){
        dateFormat = day+"-"+month+"-"+year
      }
      
      firebase.firestore().collection("students/"+this.state.user.uid+"/agenda").doc(dateFormat).get().then(doc=>{
          
          var agenda = doc.data()
          
          console.log(agenda)
          agenda[key] = task
          
          firebase.firestore().collection("students/"+this.state.user.uid+"/agenda").doc(dateFormat).update(agenda)
      }).catch(err=>{
          
          var agenda = {}
          agenda[key] = task
          firebase.firestore().collection("students/"+this.state.user.uid+"/agenda").doc(dateFormat).set(agenda)
      })

      // alert("Se agregó una nueva tarea a tu agenda :)")

      
  }

  receiveAgendaDate = (date)=>{
    this.setState({
      agendaDate:date
    })
  }

  activityAdded =()=>{
    this.setState({
      addActivity: ''
    })
  }





  

  renderView =()=>{

    switch (this.state.view) {

      case "chat":
        return(

          <div>
            <NavBar
              handleBack={this.handleViewChange}
              backView={"session-view"}
              title="Chat con Tutor"
            />

            <Chat
              child={this.state.child}
              uid={this.state.user.uid}
              handleViewChange = {this.handleViewChange}
              chatID = {this.state.user.uid}
              type="chats/"
              
              session={this.state.session}
              sessionIndex={this.state.sessionIndex}
              course={this.state.course}
            />
          </div>
          
        )


      case "friend-list":
        return(
          <div>
            <NavBar
              handleBack={this.handleViewChange}
              backView={"app"}
              title="Chatea con Amigos"
            />
            <FriendList
              uid={this.state.user.uid}
              selectFriendChat={this.selectFriendChat}
            />
          </div>
        )

      case "tutoring":
        return(
          <div>
            <NavBar
              handleBack={this.handleViewChange}
              backView={"app"}
              title="Agenda una Asesoría"
            />
            <Tutoring/>
          </div>
        )

      case "aux-renderer":
        return(
          <AuxRenderer
            course={this.state.auxCourse}
            handleViewChange={this.handleViewChange}
          />
        )
      

      case "private-chat":
        return(

          <div>
            <NavBar
              handleBack={this.handleViewChange}
              backView={"friend-list"}
              title="Chat Personal"
            />

            <GroupChat
              child={this.state.child}
              uid={this.state.user.uid}
              handleViewChange = {this.handleViewChange}
              chatID = {this.state.friendChat}
              type="chats/"
              
              session={this.state.session}
              sessionIndex={this.state.sessionIndex}
              course={this.state.course}
              reference={"private-chats/"+this.state.friendChat}
            />
          </div>
          
        )

      case "group-chat":
        return(
          <div>
            <NavBar
              handleBack={this.handleViewChange}
              backView={"session-view"}
              title="Chat Grupal"
            />

            <GroupChat
              child={this.state.child}
              uid={this.state.user.uid}
              handleViewChange = {this.handleViewChange}
              chatID = {this.state.child.group}
              course={this.state.course}
              reference={"group-chats/"+this.state.course}
            />
          </div>
          
          
        )

      case "treehouse":
        return(
          <House
            handleViewChange = {this.handleViewChange}
            child = {this.state.child}
            user = {this.state.user}

          />
          

        
        )

      case "avatar":
        return(
         
          <div>
            <NavBar
              handleBack={this.handleViewChange}
              backView={"app"}
              title="Armario"
            />

            <Avatar
              handleViewChange = {this.handleViewChange}
              child = {this.state.child}
              uid = {this.state.user.uid}
              user={this.state.user}
              changeAvatar={this.setAvatar}
            />
          </div>

        
        )

      case "app":
        return (
          <Profile
            balance={this.state.balance}
            name = {this.state.name}
            image={this.state.avatar}
            handleViewChange = {this.handleViewChange}
            user = {this.state.user}
            treehouse = {this.state.treehouse}
            child = {this.state.child}
            addTask={this.addTask}
            viewCurricularActivity={this.viewCurricularActivity}
            uid = {this.state.user.uid}
            receiveAgendaDate={this.receiveAgendaDate}
            date={this.state.agendaDate}
            addCustomTask={this.addCustomTask}

            viewLMS={this.viewLMS}
            viewSubject={this.viewSubject}
            addActivity={this.addActivity}
          />
        );
      
      case "city-map":
        return (
          <CityMap
            balance={this.state.balance}
            name = {this.state.name}
            image={this.state.avatar}
            handleViewChange = {this.handleViewChange}
            user = {this.state.user}
            treehouse = {this.state.treehouse}
            child = {this.state.child}
            addTask={this.addTask}
            viewCurricularActivity={this.viewCurricularActivity}
            uid = {this.state.user.uid}
            receiveAgendaDate={this.receiveAgendaDate}
            date={this.state.agendaDate}
            addCustomTask={this.addCustomTask}

            viewLMS={this.viewLMS}
            viewSubject={this.viewSubject}
          />
        );

      case "quiz-intro":
        return (
          <div>
            <NavBar
              handleBack={this.handleViewChange}
              backView={this.state.game+"-levels"}
              title="Quiz"
            />
            <QuizIntro
              game={this.state.game}
              stato={this.state}
              handleViewChange={this.handleViewChange}
            />
          </div>
          
        );

      case "quiz":
        return (
          <div>
            <NavBar
              handleBack={this.handleViewChange}
              backView={"quiz-intro"}
              game={this.state.game}
              title="Quiz"
            />
            <Quiz
              handleViewChange={this.handleViewChange}
              game={this.state.game}
              stato={this.state}
              userID={this.state.user.uid}
              child = {this.state.child}
            />
          </div>
              
        );
      
      case "vg1":
        return (
          <div>

            <NavBar
              handleBack={this.handleViewChange}
              backView="app"
              title="Curso de Videojuegos"
            />

            <CourseView
              userID={this.state.user.uid}
              handleSessionView = {this.handleSessionView}
              handleViewChange = {this.handleViewChange}
              // level = {2}
              handleCourseBuy = {this.handleCourseBuy}
              child = {this.state.child}
              course = {this.state.view}
              handleCourseChange={this.handleCourseChange}
            />

          </div>

        );

      case "code":
        return (
          <div>

            <NotionPage/>
{/* 
            <NavBar
              handleBack={this.handleViewChange}
              backView="app"
              title="Curso de Programación Básica"
            />

            <CourseView
              userID={this.state.user.uid}
              handleSessionView = {this.handleSessionView}
              handleViewChange = {this.handleViewChange}
              // level = {2}
              handleCourseBuy = {this.handleCourseBuy}
              child = {this.state.child}
              course = {this.state.view}
              handleCourseChange={this.handleCourseChange}
            /> */}

          </div>

        );
        case "tech101":
          return (
            <div>
  
              <NavBar
                handleBack={this.handleViewChange}
                backView="app"
                title="Curso de Diseño Básico"
              />
  
              <CourseView
                userID={this.state.user.uid}
                handleSessionView = {this.handleSessionView}
                handleViewChange = {this.handleViewChange}
                // level = {2}
                handleCourseBuy = {this.handleCourseBuy}
                child = {this.state.child}
                course = {this.state.view}
                handleCourseChange={this.handleCourseChange}
              />
  
            </div>
  
          );

        case "illustration":
          return (
            <div>
  
              <NavBar
                handleBack={this.handleViewChange}
                backView="app"
                title="Curso de Dibujo Digital"
              />
  
              <CourseView
                userID={this.state.user.uid}
                handleSessionView = {this.handleSessionView}
                handleViewChange = {this.handleViewChange}
                handleCourseBuy = {this.handleCourseBuy}
                child = {this.state.child}
                course = {this.state.view}
                handleCourseChange={this.handleCourseChange}
              />
  
            </div>
  
          );

        case "ani":
          return (
            <div>
  
              <NavBar
                handleBack={this.handleViewChange}
                backView="app"
                title="Curso de Animación"
              />
  
              <CourseView
                userID={this.state.user.uid}
                handleSessionView = {this.handleSessionView}
                handleViewChange = {this.handleViewChange}
                handleCourseBuy = {this.handleCourseBuy}
                child = {this.state.child}
                course = {this.state.view}
                handleCourseChange={this.handleCourseChange}
              />
  
            </div>
  
          );
        
        case "cocina":
          return (
            <div>
  
              <NavBar
                handleBack={this.handleViewChange}
                backView="app"
                title="Curso de Cocina"
              />
  
              <CourseView
                userID={this.state.user.uid}
                handleSessionView = {this.handleSessionView}
                handleViewChange = {this.handleViewChange}
             
                handleCourseBuy = {this.handleCourseBuy}
                child = {this.state.child}
                course = {this.state.view}
              />
  
            </div>
  
          );

        case "sci":
          return (
            <div>
  
              <NavBar
                handleBack={this.handleViewChange}
                backView="app"
                title="Curso de Ciencias"
              />
  
              <CourseView
                userID={this.state.user.uid}
                handleSessionView = {this.handleSessionView}
                handleViewChange = {this.handleViewChange}
                handleCourseBuy = {this.handleCourseBuy}
                child = {this.state.child}
                course = {this.state.view}
              />
  
            </div>
  
          );
        
        case "excel":
          return (
            <div>
  
              <NavBar
                handleBack={this.handleViewChange}
                backView="app"
                title="Curso de Hojas de Cálculo"
              />
  
              <CourseView
                userID={this.state.user.uid}
                handleSessionView = {this.handleSessionView}
                handleViewChange = {this.handleViewChange}
                handleCourseBuy = {this.handleCourseBuy}
                child = {this.state.child}
                course = {this.state.view}
              />
  
            </div>
  
          );

        case "web":
          return (
            <div>
  
              <NavBar
                handleBack={this.handleViewChange}
                backView="app"
                title="Curso de Desarrollo Web"
              />
  
              <CourseView
                userID={this.state.user.uid}
                handleSessionView = {this.handleSessionView}
                handleViewChange = {this.handleViewChange}
                handleCourseBuy = {this.handleCourseBuy}
                child = {this.state.child}
                course = {this.state.view}
                handleCourseChange={this.handleCourseChange}
              />
  
            </div>
  
          );
        
        case "robot":
          return (
            <div>
  
              <NavBar
                handleBack={this.handleViewChange}
                backView="app"
                title="Curso de Robótica"
              />
  
              <CourseView
                userID={this.state.user.uid}
                handleSessionView = {this.handleSessionView}
                handleViewChange = {this.handleViewChange}
                handleCourseBuy = {this.handleCourseBuy}
                child = {this.state.child}
                course = {this.state.view}
                handleCourseChange={this.handleCourseChange}
              />
  
            </div>
  
          );

        case "robot2":
          return (
            <div>
  
              <NavBar
                handleBack={this.handleViewChange}
                backView="app"
                title="Curso de Robótica 2"
              />
  
              <CourseView
                userID={this.state.user.uid}
                handleSessionView = {this.handleSessionView}
                handleViewChange = {this.handleViewChange}
                handleCourseBuy = {this.handleCourseBuy}
                child = {this.state.child}
                course = {this.state.view}
                handleCourseChange={this.handleCourseChange}
              />
  
            </div>
  
          );

          
  
        case "yt":
        return (
          <div>

            <NavBar
              handleBack={this.handleViewChange}
              backView="app"
              title="Curso de Youtuber"
            />

            <CourseView
              userID={this.state.user.uid}
              handleSessionView = {this.handleSessionView}
              handleViewChange = {this.handleViewChange}
              
              handleCourseBuy = {this.handleCourseBuy}
              child = {this.state.child}
              course = {this.state.view}
            />

          </div>

        );

        case "vg2":
        return (
          <div>

            <NavBar
              handleBack={this.handleViewChange}
              backView="app"
              title="Curso de Videojuegos 2"
            />

            <CourseView
              userID={this.state.user.uid}
              handleSessionView = {this.handleSessionView}
              handleViewChange = {this.handleViewChange}
              handleCourseChange = {this.handleCourseChange}
              handleCourseBuy = {this.handleCourseBuy}
              child = {this.state.child}
              course = {this.state.view}
            />

          </div>

        );

        case "vg3":
        return (
          <div>

            <NavBar
              handleBack={this.handleViewChange}
              backView="app"
              title="Curso de Videojuegos 3"
            />

            <CourseView
              userID={this.state.user.uid}
              handleSessionView = {this.handleSessionView}
              handleViewChange = {this.handleViewChange}
              
              handleCourseBuy = {this.handleCourseBuy}
              child = {this.state.child}
              course = {this.state.view}
            />

          </div>

        );

        case "model":
        return (
          <div>

            <NavBar
              handleBack={this.handleViewChange}
              backView="app"
              title="Curso de Modelado 3D"
            />

            <CourseView
              userID={this.state.user.uid}
              handleSessionView = {this.handleSessionView}
              handleViewChange = {this.handleViewChange}
              
              handleCourseBuy = {this.handleCourseBuy}
              child = {this.state.child}
              course = {this.state.view}
            />

          </div>

        );

        case "circuits":
        return (
          <div>

            <NavBar
              handleBack={this.handleViewChange}
              backView="app"
              title="Curso de Circuitos"
            />

            <CourseView
              userID={this.state.user.uid}
              handleSessionView = {this.handleSessionView}
              handleViewChange = {this.handleViewChange}
              
              handleCourseBuy = {this.handleCourseBuy}
              child = {this.state.child}
              course = {this.state.view}
            />

          </div>

        );

        case "music":
        return (
          <div>

            <NavBar
              handleBack={this.handleViewChange}
              backView="app"
              title="Curso de Producción Musical"
            />

            <CourseView
              userID={this.state.user.uid}
              handleSessionView = {this.handleSessionView}
              handleViewChange = {this.handleViewChange}
              
              handleCourseBuy = {this.handleCourseBuy}
              child = {this.state.child}
              course = {this.state.view}
            />

          </div>

        );

        case "extra-view":
          return(
            <div>
              <NavBar
                handleBack={this.handleViewChange}
                backView={this.state.course}
                title={"Actividad Extra "+(this.state.sessionIndex+1)}
              />

              <ExtraActivity
                session={this.state.session}
                sessionIndex={this.state.sessionIndex}
                child={this.state.child}
                userID={this.state.user.uid}
                course={this.state.course}
              />
            </div>
          )
        case "buy-course":
          return(
            <div>
              <NavBar
                handleBack={this.handleViewChange}
                backView={this.state.course}
                title={"Regístrate al Curso"}
              />

              
              <BuyCourse
                course={this.state.course}
                uid={this.state.user.uid}
                child={this.state.child}
              
              />
            </div>
          )


        case "extra":
          return (
            <div>
  
              <NavBar
                handleBack={this.handleViewChange}
                backView={"vg1"}
                title="Actividades Extra"
              />
  
              <ExtraLevels
                userID={this.state.user.uid}
                handleSessionView = {this.handleSessionView}
                handleViewChange = {this.handleViewChange}
                renderExtra = {this.handleExtraView}
                description="Estos son algunos ejercicios que me parecieron divertidos. No son necesarios para terminar la clase, pero podrán obtener Moritas por completarlos. 

                Después de subir su evidencia de la actividad extra encontrarán al día siguiente una nueva actividad. "
                // level = {2}
                child = {this.state.child}
                course = {this.state.view}
              />
  
            </div>
  
          );

      case "finanzas":
        return (
          <div>

            <NavBar
              handleBack={this.handleViewChange}
              backView="app"
              title="Curso de Finanzas"
            />

            <FinanceCourseView
              userID={this.state.user.uid}
              handleViewChange = {this.handleViewChange}
              // level = {2}

              handleCourseBuy = {this.handleCourseBuy}
              child = {this.state.child}
              // course = {this.state.view}
            />


          </div>

        );

      case "fin-glossary":
        return(
          <div>
            <NavBar
              handleBack={this.handleViewChange}
              backView={"app"}
              title="Glosario"
            />
            <FinGlossary/>
          </div>
        )

      case "session-view":
        return (
          <div>
            {/* <NavBar
              handleBack={this.handleViewChange}
              backView={this.state.course}
            /> */}
            <NavBar
                handleBack={this.handleViewChange}
                backView={this.state.course}
                title={this.state.session.title}
            />
            {this.state.child.ohana ? (
              <BerrySession
                session={this.state.session}
                sessionIndex={this.state.sessionIndex}
                child={this.state.child}
                userID={this.state.user.uid}
                course={this.state.course}
                handleViewChange={this.handleViewChange}
              />
            ):
              <SessionView
                session={this.state.session}
                sessionIndex={this.state.sessionIndex}
                child={this.state.child}
                userID={this.state.user.uid}
                course={this.state.course}
                handleViewChange={this.handleViewChange}
              />
            }

          </div>
          
          
        );

      case "school":
        return (
          

          <div>
            <NavBar
                handleBack={this.handleViewChange}
                backView={"app"}
                title={"Proyectos Robin"}
            />
{/*             
            <Projects
              handleProjectView={this.handleProjectView}
            /> */}

            <KhanActivites

              uid={this.state.user.uid}  
              handleActivityView={this.handleActivityView}
            />
          </div>

        );

      case "khan-activity":
        return (
          

          <div>
            <NavBar
                handleBack={this.handleViewChange}
                backView={"school"}
                title={this.state.curricularActivity.name}
            />

            <KhanActivity
              activity={this.state.curricularActivity}
            />
          </div>

        );

      case "project-view":
        return (
          <div>
            <NavBar
                handleBack={this.handleViewChange}
                backView={"school"}
                title={this.state.project.name}
            />

            <ProjectView
              id={this.state.project.id}
            />
          </div>
          

        );
        
      

      case "checkout":
        return(
          <CourseCheckout/>
        )

      case "ifa":
        return(
          <IFA/>
        )

      case "welcome":
        return (
          <div className="welcome">
            <center className="welcome-box">
              <img src={logo} className="welcome-logo" />
              <br />
              <WelcomeBox
                view={this.state.welcomeView}
                handleChangeWelcomeView={this.handleChangeWelcomeView}
                handleStartApp={this.handleStartApp}
                setUser={this.setUser}
              />
            </center>
          </div>
        );
      case "refer":
        return(
          <div>

            <NavBar
              handleBack={this.handleViewChange}
              backView="app"
              title="Tareas en Casa"
            />
            {/* <Refer
              child={this.state.user}
            /> */}
            <ChoreView
              child={this.state.child}
              uid={this.state.user.uid}  
            />
              
          </div>
        )


      case "store":
        return(
          <div>

            <NavBar
              handleBack={this.handleViewChange}
              backView="app"
              title="Tienda"
            />
            <Store
              handleViewChange = {this.handleViewChange}
              handlePurchase = {this.handlePurchase}
              child = {this.state.child}
              userID = {this.state.user.uid}
            />
          </div>
        ) 

      case "address-form":
        return (
          
          <div>
            <NavBar
              handleBack={this.handleViewChange}
              backView="store"
              title="Confirma tu Pedido"
            />
            <AddressForm
              child={this.state.child}
              uid={this.state.user.uid}
              item={this.state.purchaseItem}
              handleViewChange = {this.handleViewChange}
            />
          </div>
          
        );
        
        
    

      case "berry-store":
        return <BerryStore
          handleViewChange = {this.handleViewChange}
          child = {this.state.child}
          userID = {this.state.user.uid}
        />
      
      case "pricetag":
        // return <Pricetag
        //   handleViewChange = {this.handleViewChange}
        //   userID = {this.state.user.uid}
        //   level = {this.state.questLevel}
        // />
        return <PricetagNew
          toggleLoading = {this.toggleLoading}
          handleViewChange = {this.handleViewChange}
          userID = {this.state.user.uid}
          level = {this.state.questLevel}
          child = {this.state.child}
        />

      case "pricetag-levels":
        return(
          <div>
            <NavBar
              handleBack={this.handleViewChange}
              backView="finanzas"
              title="Pricetag"
            />
            <Levels
              // toggleLoading = {this.toggleLoading}
              handleViewChange = {this.handleViewChange}
              userID = {this.state.user.uid}
              questLevels = {this.state.questLevels}
              renderQuest = {this.renderQuest}
              game= "pricetag"
              game-img={PricetagImg}
              video="https://www.youtube.com/embed/CMP5pWgNW_4"

              // description="Robin Manager es un juego donde recibarás un reto nuevo cada día. Tomando las decisiones correctas tendrás que hacer crecer un negocio o incrementar las ventas. Pon atención porque cada decisión que tomes afecta el resultado y los Robins que puedes ganar por tus consejos."
              description="Robin Pricetag es un juego donde tendrás que identificar el precio de diferentes productos. Entre más respuestas correctas tengas y mayor sea su precio más puntos acumulas en tu ticket."
              gamePath="pricetag-progress/"
            />
          </div>
          
        )
        
        
        

      case "clicker":
        return <Game
          handleViewChange = {this.handleViewChange}
          userID = {this.state.user.uid}
        />
      case "quest-levels":
        return (

          <div>
            <NavBar
              handleBack={this.handleViewChange}
              backView="finanzas"
              title="Street"
            />
            <Levels
              handleViewChange = {this.handleViewChange}
              userID = {this.state.user.uid}
              questLevels = {this.state.questLevels}
              renderQuest = {this.renderQuest}
              game= "quest"

              video="https://www.youtube.com/embed/QskHGZOrIlo"

              description="Robin Quest es un juego donde recibarás un reto nuevo cada día. Tomando las decisiones correctas tendrás que hacer crecer un negocio o incrementar las ventas. Pon atención porque cada decisión que tomes afecta el resultado y los Robins que puedes ganar por tus consejos."
              // description="Robin Manager is a game where you will get a new challenge everyday. You’ll need to make the right choices to grow a business or increase sales. Every choice you make will affect how the business unfolds."
              gamePath="old-quest-progress/"
            />
          </div>
        )
      
      

      case "old-quest-levels":
        return (
          <div>

            <NavBar
              handleBack={this.handleViewChange}
              backView="finanzas"
              title="Street"
            />

            <Levels
              handleViewChange = {this.handleViewChange}
              userID = {this.state.user.uid}
              questLevels = {this.state.questLevels}
              renderQuest = {this.renderQuest}
              game= "old-quest"

              description="Robin Store es un juego donde recibarás un reto nuevo cada día. Tomando las decisiones correctas tendrás que hacer crecer un negocio o incrementar las ventas. Pon atención porque cada decisión que tomes afecta el resultado y los Robins que puedes ganar por tus consejos."
              // description="Robin Manager is a game where you will get a new challenge everyday. You’ll need to make the right choices to grow a business or increase sales. Every choice you make will affect how the business unfolds."
              gamePath="old-quest/"
            />
          </div>
        )



      case "old-quest":
        return <OldQuest
          toggleLoading = {this.toggleLoading}
          handleViewChange = {this.handleViewChange}
          userID = {this.state.user.uid}
          questLevel = {this.state.questLevel}
      />

      case "quest":
        return <Manager
          toggleLoading = {this.toggleLoading}
          handleViewChange = {this.handleViewChange}
          userID = {this.state.user.uid}
          questLevel = {this.state.questLevel}
        />

      case "quest-mini":
        return <ManagerMini
          toggleLoading = {this.toggleLoading}
          handleViewChange = {this.handleViewChange}
          userID = {this.state.user.uid}
          questLevel = {this.state.questLevel}
        />

      case "street-levels":
        return (
          <div>
            <NavBar
              handleBack={this.handleViewChange}
              backView="finanzas"
              title="Street"
            />
            <Levels
                handleViewChange = {this.handleViewChange}
                userID = {this.state.user.uid}
                questLevels = {this.state.questLevels}
                renderQuest = {this.renderQuest}
                game="street"

                video="https://www.youtube.com/embed/ceN867HKdno"

                // description="Robin Street es una simulación del mercado de valores. Aquí podrás comprar y vender acciones o tarjetas de diferentes empresas. Cada ronda el precio de las tarjetas cambiará, deberás comprar las tarjetas baratas y vender las caras para ganar más Robins."
                description="Robin Street es una simulación del mercado de valores. Aquí podrás comprar y vender acciones o tarjetas de diferentes empresas. Cada ronda el precio de las tarjetas cambiará, deberás comprar las tarjetas baratas y vender las caras para ganar más Robins."
                gamePath="s-progress/"
            />
          </div>
        )
        
        

      case "gourmet-levels":
        return (
          <div>
            <NavBar
              handleBack={this.handleViewChange}
              backView="finanzas"
              title="Gourmet"
            />

            <Levels
                toggleLoading = {this.toggleLoading}
                handleViewChange = {this.handleViewChange}
                userID = {this.state.user.uid}
                child={this.state.user}
                questLevels = {this.state.questLevels}
                renderQuest = {this.renderQuest}
                game="gourmet"

                video="https://www.youtube.com/embed/RgPk7oLqAGQ"

                // description="Robin Street es una simulación del mercado de valores. Aquí podrás comprar y vender acciones o tarjetas de diferentes empresas. Cada ronda el precio de las tarjetas cambiará, deberás comprar las tarjetas baratas y vender las caras para ganar más Robins."
                description="Robin Gourmet es un juego donde tendrás que manejar un restaurante. Aquí tu decidirás que platillos preparar para obtener la mayor ganancia."
                gamePath="gourmet-progress/"
            />

          </div>

        )
        
        
        

      case "gourmet":
        return <Gourmet
          toggleLoading = {this.toggleLoading}
          handleViewChange = {this.handleViewChange}
          userID = {this.state.user.uid}
          level = {this.state.questLevel}
          child = {this.state.child}
      />

      case "street":
        return <Street
          toggleLoading = {this.toggleLoading}
          handleViewChange = {this.handleViewChange}
          userID = {this.state.user.uid}
          level = {this.state.questLevel}
          child = {this.state.child}
      />

      case "join-wallstreet":
        
        return <div>
          <NavBar
              handleBack={this.handleViewChange}
              backView="app"
              title="Wallstreet"
            />

          <JoinWallstreet
            joinWallstreetGame={this.joinWallstreetGame}
            uid={this.state.user.uid}
            child={this.state.child}
          />
        </div>
        

      case "wallstreet":
        
        return <WallStreet
            toggleLoading = {this.toggleLoading}
            child={this.state.child}
            uid={this.state.user.uid}
            gameID={this.state.gameID}
            handleViewChange={this.handleViewChange}
          />


      
      case "subscription":
        return <Subscription
          handleViewChange = {this.handleViewChange}
          userID = {this.state.user.uid}
          child={this.state.child}
      />

      case "treehouse-old":
        return <Treehouse
          handleViewChange = {this.handleViewChange}
          user = {this.state.user.uid}
        />

      case "lms":
        return <LMS
          viewCurricularActivities={this.viewCurricularActivities}
          subject={this.state.subject}
          handleViewChange={this.handleViewChange}
          uid={this.state.user.uid}
          child={this.state.child}
        />

      //replaces lms view in new notion update
      case "subject":
        return <Subject
          viewCurricularActivities={this.viewCurricularActivities}
          subject={this.state.subject}
          handleViewChange={this.handleViewChange}
          uid={this.state.user.uid}
          child={this.state.child}
          viewTopic={this.viewTopic}
          subjectGrade={this.state.subjectGrade}
          avatar={this.state.avatar}
        />
      

       // Replaces curricular-activities view
      case "subject-selector":
        return <div>
          <NavBar
            handleBack={this.handleViewChange}
            
            backView="agenda"
            title="Materias"
          />
          <SubjectSelector
            handleViewChange={this.handleViewChange}
            viewLMS={this.viewLMS}
            viewSubject={this.viewSubject}
            child={this.state.child}
            avatar={this.state.avatar}
          />
        </div>

        // Replaces curricular-activities view
      case "topic":
        return <Topic
          topic={this.state.LMSTopic}
          viewCurricularActivity={this.viewCurricularActivity}
          back="lms"
          addActivity={this.addActivity}
          handleBack={this.handleViewChange}
          uid = {this.state.user.uid}
          child={this.state.child}
          subject={this.state.subject}
          grade={this.state.selectedGrade}
          avatar={this.state.avatar}
        />     
        

      case "agenda":
        return <div>
          <NavBar
              handleBack={this.handleViewChange}
              backView={"app"}
              title="Agenda"
            />
          <Agenda
            addTask={this.addTask}
            handleViewChange={this.handleViewChange}
            viewCurricularActivity={this.viewCurricularActivity}
            uid = {this.state.user.uid}
            child={this.state.child}
            receiveAgendaDate={this.receiveAgendaDate}
            date={this.state.agendaDate}
            addCustomTask={this.addCustomTask}
            addActivity={this.addActivity}
          />
        </div>
        
        
     
        

      case "language":
        return <div>
          <NavBar
            handleBack={this.handleViewChange}
            backView="agenda"
            title="Materias"
          />

          <LanguageAreas
            viewLanguageActivities={this.viewLanguageActivities}
            handleViewChange={this.handleViewChange}
            
          />
        </div>
        
        
      
      case "language-activities":
         return <LanguageActivities
          area={this.state.languageArea}
          viewCurricularActivity={this.viewCurricularActivity}
          back="language"
          handleBack={this.handleViewChange}
          addActivity={this.addActivity}
          uid = {this.state.user.uid}
          child={this.state.child}
        />

      case "recurrent-activities":
         return <RecurrentActivities
          area={this.state.languageArea}
          viewCurricularActivity={this.viewCurricularActivity}
          back="language"
          handleBack={this.handleViewChange}
          addActivity={this.addActivity}
          uid = {this.state.user.uid}
          child={this.state.child}
        />

      case "curricular-activities":
        return <CurricularActivities
          topic={this.state.LMSTopic}
          viewCurricularActivity={this.viewCurricularActivity}
          back="lms"
          addActivity={this.addActivity}
          handleBack={this.handleViewChange}
          uid = {this.state.user.uid}
          child={this.state.child}
          subject={this.state.subject}
          grade={this.state.selectedGrade}


      />

      case "projects-library":
        return <ProjectsLibrary
          
          viewCurricularActivity={this.viewCurricularActivity}
          back="subjects"
          addActivity={this.addActivity}
          handleBack={this.handleViewChange}
          uid = {this.state.user.uid}
          child={this.state.child}
          subject={this.state.subject}
          grade={this.state.selectedGrade}
        />

      case "curricular-activity":
        return <CurricularActivity
          activity={this.state.LMSActivity}
          backView={this.state.backView}
          handleBack={this.handleViewChange}
          uid = {this.state.user.uid}
          child={this.state.child}
          activityID={this.state.activityID}

          avatar={this.state.avatar}
        />  
      case "school-options":
        return <div>
          <NavBar
            handleBack={this.handleViewChange}
            backView="app"
            title="Robin School"
          />

          <SchoolOptions
            handleViewChange={this.handleViewChange}
            child={this.state.child}
          />
        </div>

      case "student-uploads":
        return <div>
          <NavBar
              handleBack={this.handleViewChange}
              backView={"school-options"}
              title="Actividades por revisar"
          />
          <StudentUploads
            handleViewChange={this.handleViewChange}
            viewCurricularActivity={this.viewCurricularActivity}
            uid = {this.state.user.uid}
            child={this.state.child}
          />
        </div>
      case "comments-viewer":
        return <div>
          <NavBar
              handleBack={this.handleViewChange}
              backView="app"
              title={"Actividades con Comentarios"}
            />
          <CommentsViewer
            uid={this.state.user.uid}
            viewCurricularActivity={this.viewCurricularActivity}
          />
          
        </div> 
        
        


    }
  }

  closeModal = (notificationID) =>{
    console.log(this.state.user.uid)
    firebase.database().ref("notifications/"+this.state.user.uid+"/"+notificationID).set(null)

  }


  render() {

    

    console.log(this.state)


    var testmodal = {
      dialogue: "¡Felicidades! Por la última evidencia que enviaste ganaste 50 Robins y un accesorio.",
      img:"https://image.flaticon.com/icons/svg/878/878052.svg",
      // images: ["https://image.flaticon.com/icons/svg/878/878052.svg", "https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/hq%2Ftreehouse-red.png?alt=media&token=b74864e2-419f-4613-ab87-622c7595c798"],
      options: {
          ok: ()=>{this.closeModal()}
      }
    }

    testmodal = this.state.notifications

    
    return(
      <div>
        { this.state.loading &&

          <div className="super-modal">
            <div className="loading">
              <img className="loading-img" src={Loading}></img>
            </div>
          </div>
        }

        { this.state.loading &&

          <div className="super-modal">
            <div className="loading">
              <img className="loading-img" src={Loading}></img>
            </div>
          </div>
        }

        {(this.state.addActivity && this.state.user)&&
          
          <AddActivityModal
            activity={this.state.addActivity}
            uid={this.state.user.uid}
            child={this.state.child}
            activityAdded={this.activityAdded}
            addTask={this.addTask}
          />

        }

        {this.state.notificationArray.map(notification=>{
          return(
            <Modal
              modal={notification}
            />
          )
          
        })

        }
        {/* { this.state.notifications &&
          <Modal
            modal = {testmodal}
            // options={this.state.modal.options}
            // dialogue={this.state.modal.dialogue}
            // img={this.state.modal.img}
            // title={this.state.modal.title}
          />
        } */}

        {(!this.state.tutorial && this.state.user) &&
          <div>
            <VideoModal
              video="https://www.youtube.com/embed/dfT5cJmP8VE"
              handleFinishTutorial={this.handleFinishTutorial}
            />
            
          </div>
        }
        {this.renderView()}
      </div>
      
    )
    
  }
}

export default App;

import React, {useState, useEffect} from 'react'

import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import TopicBox from './topic-box';
import NavBar from '../navigation/nav-bar';
import GradeSelector from './grade-selector';

const Subject = (props)=>{

    console.log(props)

    var auxGrade = props.subjectGrade[props.subject]
    if(!auxGrade){
        auxGrade="7"
    }
    
    const [notionData, setNotionData] = useState([]);

    const [topics, setTopics] = useState([])

    const [grade, setGrade] = useState(auxGrade)
    const [toggle, setToggle] = useState(false)
    
    
    const subjectsWithGrades = {
        "Math": true, 
        "Science": true, 
        "Lengua Materna": true, 
        "Citizenship": true
    }

    useEffect(()=>{
        

        
        const query = {
            database:"1adac672a1464d60aff992a1d1c000b4",
            filter: {
                and: [
                    {
                        property: 'Subject',
                        select: {
                            equals: props.subject
                        }
                    },
                    {
                        property: 'Grade',
                        select: {
                            equals: grade
                        }
                    }
                ]
            },
            sorts:[
                {
                    property: 'Sequence',
                    direction: 'ascending'
                }
            ]
        }

        const notionQuery = firebase.functions().httpsCallable("notionQuery")
        notionQuery(query)
            .then(response=>{
                setNotionData(response.data);
                console.log(response.data)
                setTopics(response.data.results)
            })
        
        const data = props.subjectGrade
        data[props.subject] = grade

        firebase.firestore().collection("curricular-progress").doc(props.uid).set(data)
        
    }, [grade])

    console.log(props)

    const handleToggle=(grade)=>{
        console.log(grade)
        setToggle(!toggle)

        setGrade(grade)

        if(grade){

            firebase.firestore().collection("curricular-progress").doc(props.uid).get().then(doc=>{

                var progress = doc.data()

                if(!progress){
                    progress = {}
                }

                progress[props.subject] = grade

                firebase.firestore().collection("curricular-progress").doc(props.uid).set(progress)
            })

            
        }
    }

    return(
        
        <div className="topic-selector-view">
            <img className="lms-avatar" src={props.avatar}></img>
            <NavBar
                
                handleBack={props.handleViewChange}
                backView="app"
                title={props.subject}
            
            />

            {subjectsWithGrades[props.subject]&&
                <GradeSelector
                grade={grade}
                setGrade={setGrade}
                toggle={toggle}
                handleToggle={handleToggle}
            />

            }
            
            <div className="topics-container">
                {topics.map(topic =>{
                    console.log(topic)
                    console.log(topic.properties.Name.title[0].plain_text)
                    return(
                        <div className='shelf-spot'>
                            <TopicBox
                                name={topic.properties.Name.title[0].plain_text}
                                points={"5"}
                                topic_id={topic.id}
                                img={""}
                                viewTopic={props.viewTopic}
                            />
                        </div>

                        
                    )
                })}
            </div>
            

        </div>
    )
}

export default Subject
import React from 'react'
import Bar from '../treehouse/bar'
import PurpleArrow from '../../assets/images/purple-right-arrow.png'

import './topic-box.css'


const TopicBox = (props)=>{

    var points = 0

    // if(props.points){
    //     if(props.points[props.topic_key]){
    //         points = props.points[props.topic_key]
    //     }    
    // }

    // var totalPoints = props.topic.totalPoints

   
    return(
        <div className="topic-box topic-book-background" onClick={()=>{props.viewTopic(props.topic_id)}}>
            {/* <img src={props.img} className="topic-img"></img> */}
            <div className='book-top'>

            </div>
            <div className="book-cover">
                <p className="topic-title">{props.name}</p>
                {/* <Bar
                    max={totalPoints}
                    progress={points}
                    color={'#3DD869'}
                /> */}
            </div>

            

            
        </div>
    )
}

export default TopicBox
import React, {useState, useEffect} from 'react'

import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import TopicBox from './topic-box';
import ActivityBox from './activity-box';
import NavBar from '../navigation/nav-bar';

const Topic = (props)=>{

    const [notionData, setNotionData] = useState([]);

    const [topics, setTopics] = useState([])

    const [uploadedActivities, setUploadedActivities] = useState({})
    
    console.log(props)
    useEffect(()=>{

        const query = {
            database:"396c609207ef4df3a3ade19f60f37646",
            filter: {
                and: [
                    {
                        property: 'Topic',
                        relation: {
                            contains: props.topic
                        }
                    }
                ]
            },
            sorts: [
                {
                    property: 'secuencia',
                    direction: 'ascending'
                }
            ]
        }

        const notionQuery = firebase.functions().httpsCallable("notionQuery")
        notionQuery(query)
            .then(response=>{
                setNotionData(response.data);
                console.log(response.data)
                setTopics(response.data.results)
            })

        

        firebase.firestore().collection("students/"+props.uid+"/uploads").onSnapshot((snap)=>{

            let uploadedActivities = {}
            snap.docs.forEach(doc=>{
                uploadedActivities[doc.id]=doc.data()
            })

            setUploadedActivities(uploadedActivities)


        })
        
    }, [])


    return(
        <div className="activity-selector-view">
            <img className="lms-avatar" src={props.avatar}></img>

            <NavBar
                    handleBack={props.handleBack}
                    backView="subject"
                    title="Actividades del Tema"
            />

            <div className='activity-list'>
                {topics.map(topic =>{
                    console.log(topic)
                    topic.activity_key=topic.id
                    topic.name = topic.properties.nombre.title[0].plain_text
                    return(
                        
                        <ActivityBox
                            name={topic.name}
                            uploadedActivity={uploadedActivities[topic.id]}
                            addActivity={props.addActivity}
                            activity_key={topic.id}
                            activity={topic}
                            backView="topic"
                            viewCurricularActivity={props.viewCurricularActivity}
                        />
                    )
                })}
            </div>
            

        </div>
    )
}

export default Topic
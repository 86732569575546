import React from 'react';
import * as PIXI from 'pixi.js'
import {Mixpanel} from '../../../Mixpanel'

import Inventory from '../../../assets/images/inventario.png'
import Edit from '../../../assets/images/editar.png'
import White from '../../../assets/images/white.png'
import Store from '../../../assets/images/treehouse-bg.png'
import Left from '../../../assets/images/left.png'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/database';;



import './house.css'

import { Stage, Sprite } from '@pixi/react'
import HouseInventory from './house-inventory';

class House extends React.Component {
    constructor(){
        super()

        this.state = { 
            downX: 0,
            editing: false,
            height: window.innerHeight, 
            width: window.innerWidth, 
            x: 0,
            y: 0, 
            positionRatio: 1930/window.innerHeight,
            objects:[
                
            ], 
            inventory: [
                
            ]
        };

        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#000000"
        document.body.style.backgroundImage = `none`
        Mixpanel.track('Treehouse View');

        firebase.database().ref("treehouse/"+this.props.user.uid).once("value", (snap)=>{

            const treehouse = snap.val()


            if(treehouse){

                const objects = treehouse.objects

                const inventory = treehouse.inventory

                if(objects){
                    this.setState({
                        objects: treehouse.objects, 
                    })
                }
                
                if(inventory){
                    this.setState({
                        inventory: treehouse.inventory, 
                    })
                }
            }
        })

        window.addEventListener("resize", this.updateDimensions);

    }

    updateDimensions() {
        this.setState({
            height: window.innerHeight, 
            width: window.innerWidth
        })
    }

    handleQuit = () => {

        var viewInventory = this.state.viewInventory

        

        var objects = this.state.objects
    
        var newObjects = []

        if(viewInventory){
    
            for( var i = 0; i < objects.length; i++){ 
                var object = objects[i]
                
                object.y = object.y + 500
        
                
                newObjects.push(object)
                
            }
            objects = newObjects
        }

        const treehouse = {
            objects: objects, 
            inventory: this.state.inventory
        }

        firebase.database().ref("treehouse/"+this.props.user.uid).set(treehouse)

        this.props.handleViewChange("app")

    }

    downInventory = (object, index) => {
        var inventory = this.state.inventory
        var objects = this.state.objects

        inventory.splice(index,1)

        object.x = window.innerWidth/2
        object.y = window.innerHeight/2
        objects.push(object)

        this.setState({
            inventory: inventory,
            objects: objects, 
            dragging: object
        })

    }
    

    onMouseMove(e){

        

        // console.log(this.state)

        //Hay un desfazamiento en X que se incrementa cuando incrementa x. Tambien en Y. el desfazamiento es 
        
        if(this.state.mouseDown){
            const scroll = document.body.scrollLeft || document.documentElement.scrollLeft
            var mouseX = e.clientX + scroll || e.touches[0].clientX + scroll
            var mouseY = e.clientY || e.touches[0].clientY

            

            mouseX = mouseX*this.state.positionRatio
            mouseY = mouseY*this.state.positionRatio


            if(!this.state.dragging){

                // const downX = this.state.downX

                // const moveX=downX-mouseX
                // // console.log("downX: "+downX)
                // // console.log("mouseX: "+mouseX)
                // // console.log("moveX: "+moveX)
                // window.scroll(moveX, 0)

            }
           
            
            const objects = this.state.objects
            const inventory = this.state.inventory

            objects.map((object, index)=>{

                const vh = this.state.height

                const ratio = vh/1930

                const endX = object.x+object.width
                const endY = object.y+object.height


                const offsetX = mouseX - object.x 
                


                //Activar solo cuando el objeto es dragable
                //Dragable = true cuando mouseDown esta dentro de sus coordenadas



                if(this.state.dragging){
                    const oldObjects = this.state.objects
                    
                    // object.x = mouseX-(object.width/2)
                    // object.y = mouseY-(object.height/2)
                

                    // oldObjects.splice(index,1)
                    // oldObjects.push(object)

                    // this.setState({
                    //     objects: oldObjects
                    // })


                    const draggingObject = this.state.dragging

                    for( var i = 0; i < oldObjects.length; i++){ 
                        if (oldObjects[i] === draggingObject) {
                            oldObjects.splice(i, 1)
                            i--
                        }
                    }

                    //MouseX es la unica variable que está creciendo
                    //draggingObject.x = mouseX-(object.width*(mouseX/window.innerWidth))
                    draggingObject.x = mouseX-(object.width/2)
                    draggingObject.y = mouseY-(object.height/2)

                    oldObjects.reverse()
                    oldObjects.push(draggingObject)

                    this.setState({
                        objects: oldObjects, 
                        draggingObject: draggingObject
                    })

                }

            
            })

            

            


        
        }
    }


    onMouseUp = (e) =>{

        this.setState({
            downX:0, 
            mouseDown: false
        })
        
        var draggingObject = this.state.dragging
        if(draggingObject){
            var mouseY = (draggingObject.y+(draggingObject.height/2))/this.state.positionRatio
            const limitY = this.state.height-(500/this.state.positionRatio)

            if(this.state.viewInventory&&mouseY>=limitY){
                var inventory = this.state.inventory
                var objects = this.state.objects
    
                inventory.push(this.state.dragging)
    
                for( var i = 0; i < objects.length; i++){ 
                    if (objects[i] === this.state.dragging) {
                        objects.splice(i, 1)
                        i--
                    }
                }
    
                this.setState({
                    inventory:inventory, 
                    objects
                })
            }
    
            this.setState({ 
                mouseDown: false, 
                dragging: ""
            });
        }

        
        // console.log(mouseY)
        // console.log(this.state.positionRatio)
        // console.log(limitY)

        


        
    }



    drawImg = (object) => {

        

        const vh = this.state.height

        const ratio = vh/1930



        var x = object.x
        var y = object.y

        return(
            <Sprite image={object.img} x={x*ratio} y={y*ratio} height={object.height*ratio} width={object.width*ratio}/>
        )

    }

    mouseDown = (e) => {

        // this.setState({
        //     mouseDown: true
        // })



        const scroll = document.body.scrollLeft || document.documentElement.scrollLeft || window.pageXOffset

        console.log(scroll)
        var mouseX = e.clientX + scroll || e.touches[0].clientX + scroll
        var mouseY = e.clientY || e.touches[0].clientY

        


        mouseX = mouseX*this.state.positionRatio
        mouseY = mouseY*this.state.positionRatio

        this.setState({
            downX: mouseX, 
            mouseDown: true
        })

        if(this.state.viewInventory){
            const inventory = this.state.inventory

            inventory.map((item, index)=>{
                const startX = index*300+100 + scroll*this.state.positionRatio
                const endX = (index+1)*300+100 + scroll*this.state.positionRatio

                const limitY = this.state.height-(500/this.state.positionRatio)
                
                if(mouseX>=startX&&mouseX<=endX&&(mouseY/this.state.positionRatio>=limitY)){
                    inventory.splice(index,1)
                    var objects = this.state.objects

                    item.x = mouseX-(item.width/2)
                    item.y = mouseY-(item.height/2)

                    objects.push(item)
                    this.setState({
                        objects: objects
                    })
                }
            })
        }


        if(this.state.editing){
            const objects = this.state.objects


            var reverseObjects = objects.slice(0)

            reverseObjects.reverse()



            objects.map((object, index)=>{

                var x = object.x
                var y = object.y

                const endX = x+object.width
                const endY = y+object.height

            
            


                const offsetX = mouseX - x 

                const ratio = this.state.positionRatio
                mouseX = mouseX
                mouseY = mouseY


                //Activar solo cuando el objeto es dragable
                //Dragable = true cuando mouseDown esta dentro de sus coordenadas


                if((mouseX>=x&&mouseX<=endX)&&(mouseY>=y&&mouseY<=endY)&&(!this.state.dragging)){

                    

                    const oldObjects = objects
                    
                    object.isDragging = true


                    oldObjects.splice(index,1)
                    oldObjects.push(object)
                    


                    this.setState({
                        objects: oldObjects, 
                        dragging: object
                    })
                }

                
                
            })
        }
        

        


    }

    drawInventory = (item, index) => {
        
        
        if(this.state.viewInventory){
           

            const vh = this.state.height
            const ratio = vh/1930

            const maxSquare = 300/this.state.positionRatio

                
                var height=item.height*this.state.positionRatio
                var width=item.width*this.state.positionRatio



                var localRatio = 1

                if (height>maxSquare){
                    localRatio = maxSquare/height
                    height=height*localRatio
                    width= width*localRatio
                }
                if (width>maxSquare){
                    localRatio = maxSquare/width
                    height=height*localRatio
                    width= width*localRatio
                }
        
                var itemX = ((index*300)+100+(index*50))/this.state.positionRatio

                itemX = itemX + (document.body.scrollLeft || document.documentElement.scrollLeft || window.pageXOffset)

                // var itemY = 1900/this.state.positionRatio
                var itemY = this.state.height-(height)-(50/this.state.positionRatio)

                console.log(index)
                console.log(item.img, itemX, itemY, height, width)
                return(
                    <Sprite image={item.img} x={itemX} y={itemY} height={height} width={width}/>
                )
            
        }
    }

    inventoryToggle = () => {
        var viewInventory = this.state.viewInventory

        var objects = this.state.objects
    
        var newObjects = []
    
        for( var i = 0; i < objects.length; i++){ 
            var object = objects[i]
            if(!viewInventory){
                object.y = object.y - 500
            }
            else{
                object.y = object.y + 500
            }
            
            newObjects.push(object)
            
        }

        this.setState({
            editing: !viewInventory, 
            objects: newObjects, 
            viewInventory: !viewInventory
        })
    }

    editingToggle = () => {
        var editing = this.state.editing

        
        this.setState({
            editing: !editing, 
        })

        if(this.state.viewInventory){
            var objects = this.state.objects
        
            var newObjects = []
        
            for( var i = 0; i < objects.length; i++){ 
                var object = objects[i]
                
                object.y = object.y + 500
                
                
                newObjects.push(object)
                
            }

            this.setState({
                viewInventory: false, 
                objects: newObjects
            })
        }
    }

    
    

    render(){

        const vh = this.state.height

        
        // console.log(this.state)

        const ratio = vh/1570

        var coinHeight = 152*ratio

        const objects = this.state.objects
        const inventory = this.state.inventory

        var backgroundHeight = 0

        if(this.state.viewInventory){
            backgroundHeight= -500/this.state.positionRatio
        }

        // console.log("height: "+vh)
        // console.log("width: "+4000*ratio)

        // var isDragging = "block"

        var bufferStyle={
            // pointerEvents: "all",
            height: vh,
            width: 4000*ratio
        }

        // if(this.state.dragging){
        //     bufferStyle={
        //         display: "none",
        //         pointerEvents: "none",
        //         height: `${vh}`,
        //         width:`${4000*ratio}`
        //     }
        // }

        var bgAlpha=1

        if(this.state.editing){
            bgAlpha=0.6
        }

        return(
            <div onMouseDown={(e)=>{this.mouseDown(e)}} onTouchStart={(e)=>{this.mouseDown(e)}} onTouchMove={(e)=>{this.onMouseMove(e)}} onMouseMove={(e)=>{this.onMouseMove(e)}} onMouseUp={(e)=>{this.onMouseUp(e)}} onTouchEnd={(e)=>{this.onMouseUp(e)}} className="house-background-">
                

                <div onClick={()=>{this.editingToggle()}}>
                    <img className="edit-btn" src={Edit} ></img>
                </div>

                <div onClick={()=>{this.inventoryToggle()}} >
                    <img className="inventory-btn" src={Inventory} style={{top: vh-60+backgroundHeight}}></img>
                </div>


                <div
                    className="house-icon" 
                    onClick={()=>this.handleQuit()}
                >
                    <img className="house-back"src={Left}></img>
                </div>

                {!this.state.editing &&
                    <div className="buffer" style={bufferStyle}>
                        
                    </div>
                }

                

                
                

                <Stage ref="canvas" id={"canvas"} height={vh} width={4000*ratio} options={{ transparent: true, antialias: false,  }} >

                    
                    <Sprite alpha={bgAlpha} image={Store} x={0} y={backgroundHeight} height={vh} width={4000*ratio} />

                    <Sprite image={White} x={0} y={vh+backgroundHeight} height={500} width={4000*ratio} />
                    

                            {/* {inventory.map((item, index)=>{
                                if(inventory[index]){

                                    this.drawInventory(inventory[index], index)        
                                }
                            })
                            } */}

                            {inventory[0] &&
                                this.drawInventory(inventory[0], 0)
                            }
                            {inventory[1] &&
                                this.drawInventory(inventory[1], 1)
                            }
                            {inventory[2] &&
                                this.drawInventory(inventory[2], 2)
                            }
                            {inventory[3] &&
                                this.drawInventory(inventory[3], 3)
                            }
                            {inventory[4] &&
                                this.drawInventory(inventory[4], 4)
                            }
                            {inventory[5] &&
                                this.drawInventory(inventory[5], 5)
                            }
                            {inventory[6] &&
                                this.drawInventory(inventory[6], 6)
                            }
                            {inventory[7] &&
                                this.drawInventory(inventory[7], 7)
                            }
                            {inventory[8] &&
                                this.drawInventory(inventory[8], 8)
                            }
                            {inventory[9] &&
                                this.drawInventory(inventory[9], 9)
                            }
                            {inventory[10] &&
                                this.drawInventory(inventory[10], 10)
                            }
                            {inventory[11] &&
                                this.drawInventory(inventory[11], 11)
                            }
                            {inventory[12] &&
                                this.drawInventory(inventory[12], 12)
                            }
                            {inventory[13] &&
                                this.drawInventory(inventory[13], 13)
                            }
                            {inventory[14] &&
                                this.drawInventory(inventory[14], 14)
                            }
                            {inventory[15] &&
                                this.drawInventory(inventory[15], 15)
                            }
                            {inventory[16] &&
                                this.drawInventory(inventory[16], 16)
                            }
                            {inventory[17] &&
                                this.drawInventory(inventory[17], 17)
                            }
                            {inventory[18] &&
                                this.drawInventory(inventory[18], 18)
                            }
                            {inventory[19] &&
                                this.drawInventory(inventory[19], 19)
                            }
                            {inventory[20] &&
                                this.drawInventory(inventory[20], 20)
                            }
                            {inventory[22] &&
                                this.drawInventory(inventory[21], 21)
                            }
                            {inventory[23] &&
                                this.drawInventory(inventory[21], 21)
                            }
                            {inventory[24] &&
                                this.drawInventory(inventory[21], 21)
                            }
                            {inventory[25] &&
                                this.drawInventory(inventory[21], 21)
                            }
                            {inventory[26] &&
                                this.drawInventory(inventory[21], 21)
                            }
                            {inventory[27] &&
                                this.drawInventory(inventory[21], 21)
                            }
                            {inventory[28] &&
                                this.drawInventory(inventory[21], 21)
                            }
                            {inventory[29] &&
                                this.drawInventory(inventory[21], 21)
                            }
                            {inventory[30] &&
                                this.drawInventory(inventory[21], 21)
                            }
                            {inventory[31] &&
                                this.drawInventory(inventory[21], 21)
                            }









                        {objects[0] &&
                            this.drawImg(objects[0])
                        }
                        {objects[1] &&
                            this.drawImg(objects[1])
                        }
                        {objects[2] &&
                            this.drawImg(objects[2])
                        }
                        {objects[3] &&
                            this.drawImg(objects[3])
                        }
                        {objects[4] &&
                            this.drawImg(objects[4])
                        }
                        {objects[5] &&
                            this.drawImg(objects[5])
                        }
                        {objects[6] &&
                            this.drawImg(objects[6])
                        }
                        {objects[7] &&
                            this.drawImg(objects[7])
                        }
                        {objects[8] &&
                            this.drawImg(objects[8])
                        }
                        {objects[9] &&
                            this.drawImg(objects[9])
                        }
                        {objects[10] &&
                            this.drawImg(objects[10])
                        }
                        {objects[11] &&
                            this.drawImg(objects[11])
                        }
                        {objects[12] &&
                            this.drawImg(objects[12])
                        }
                        {objects[13] &&
                            this.drawImg(objects[13])
                        }
                        {objects[14] &&
                            this.drawImg(objects[14])
                        }
                        {objects[15] &&
                            this.drawImg(objects[15])
                        }
                        {objects[16] &&
                            this.drawImg(objects[16])
                        }
                        {objects[17] &&
                            this.drawImg(objects[17])
                        }
                        {objects[18] &&
                            this.drawImg(objects[18])
                        }
                        {objects[19] &&
                            this.drawImg(objects[19])
                        }
                        {objects[20] &&
                            this.drawImg(objects[20])
                        }
                        {objects[21] &&
                            this.drawImg(objects[21])
                        }
                        {objects[22] &&
                                this.drawInventory(objects[21], 21)
                            }
                            {objects[23] &&
                                this.drawInventory(objects[21], 21)
                            }
                            {objects[24] &&
                                this.drawInventory(objects[21], 21)
                            }
                            {objects[25] &&
                                this.drawInventory(objects[21], 21)
                            }
                            {objects[26] &&
                                this.drawInventory(objects[21], 21)
                            }
                            {objects[27] &&
                                this.drawInventory(objects[21], 21)
                            }
                            {objects[28] &&
                                this.drawInventory(objects[21], 21)
                            }
                            {objects[29] &&
                                this.drawInventory(objects[21], 21)
                            }
                            {objects[30] &&
                                this.drawInventory(objects[21], 21)
                            }
                            {objects[31] &&
                                this.drawInventory(objects[21], 21)
                            }

                        

                </Stage>

                
                
            </div>
        )
    }
}

export default House





//Algo está causando un re-render cuando hago click en el canvas. Se redibuja el background sobre la moneda que dibujé previamente.

//Las coordenadas del mouse no concuerdan con las del canvas